export default {
    alerts: {
        show: {
            error: 'Failed fetching the next up information',
        },
        update: {
            error: 'Failed updating the next up information',
            success: 'Next up updated successfully',
        },
        destroy: {
            error: 'Failed removing the advisor in the next up information',
        },
        gunmiss: {
            error: 'Failed to update the gun miss information on the advisor',
        },
    },
    modals: {
        update: {
            title: '',
        },
        delete: {
            title: '',
        },
    },
    appointmentCounter: 'Number of appointments per day',
    break_date: 'Break',
    delivery_date: 'Delivery',
    deskManagerAdvisor: 'Desk manager',
    deskManagerVehicle: 'Desk manager',
    financialAdvisor: 'Financial directors',
    financialVehicle: 'Financial directors',
    gunMiss: 'Gun miss',
    inBreak: 'Break in progress',
    inDelivery: 'Delivery in progress',
    inMeeting: 'Meeting in progress',
    inPlannedMeeting: 'In planned meeting',
    meeting_date: 'Meeting',
    message_over_ten: 'A maximum of 10 users will be seen in the next up queue',
    newAdvisor: 'Advisor - New',
    newVehicle: 'New vehicle',
    noDeskManager: 'No desk manager available',
    noDirector: 'No manager available',
    noPlannedMeeting: 'No appointment scheduled in the calendar within the next 4 hours',
    noUser: 'No advisor available',
    onlyDirectorCanEdit:
        'Depending on the options in your account, only the managers can change the statuses (meeting, delivery, break)',
    relevantLeads: 'Here are your clients of the day',
    resetGunMiss: 'Reset gun miss',
    usedAdvisor: 'Advisor - Used',
    usedVehicle: 'Used vehicle',
};
