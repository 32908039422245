export default {
    activated: 'Activated',
    active: 'Active',
    applyAndReload: 'Apply and reload',
    autoActivated: 'Auto-activated',
    availableSince: 'Available since {0}',
    browserNotSupportingVideoPlaying: 'Your browser does not support video playing.',
    deactivated: 'Deactivated',
    giveFeedback: 'Give feedback',
    newTag: 'New',
    notAutoActivated: 'Not auto-activated',
    projectedAdoptionOn: 'Projected adoption on {0}',
    requestFeedback: 'Would you like to give us your feedback on the feature <strong>{0}</strong>?',
    title: 'Feature Previews',
    willBe: 'Will be',

    newLeadPage: {
        feature1: 'Complete overhaul of the lead page',
        feature2: 'User-friendly-interface',

        img1Placeholder: 'New design',
    },
};
