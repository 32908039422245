export default {
    advancedStats: {
        boxTitles: {
            phoneUp: 'Phone-up',
            renewal: '@.capitalize:lexicon.renewal.singular',
            total: 'Total activities',
            walkIn: '@.capitalize:lexicon.walkIn.singular not @:lexicon.sold.singular',
            web: 'Web Lead',
        },
        objectiveTooltip: 'Your objectives are calculated in proportion to the number of days selected',
    },
    boxTitles: {
        resultsByAdvisor: 'Results by advisor',
        resultsByAgent: 'Results by agent',
    },
    objective: 'Objective',
    objectives: {
        agent: 'Agent',
    },
    ratio: 'Ratio',
    resultsByUser: {
        advisors: 'Advisors',
        agents: 'Agents',
        noResults: 'No results',
        outgoingTextMessagesTooltip: 'The calculation is based on each series of SMS sent every 2h',
    },
    stats: {
        activities: 'Activities',
        appointments: 'Appointment | Appointments',
        beBacks: 'B-back | B-backs',
        calls: 'Calls',
        deliveries: 'Delivery | Deliveries',
        emails: 'Emails',
        incoming: 'Incoming',
        incomingActivities: 'Incoming activities',
        incomingCalls: 'Phone-up',
        incomingCommunications: 'Incoming communication | Incoming communications',
        incomingEmails: 'Incoming email | Incoming emails',
        incomingTextMessages: 'Incoming SMS | Incoming SMS',
        leads: 'Leads',
        leadsWorked: 'Lead worked | Leads worked',
        outgoing: 'Outgoing',
        outgoingActivities: 'Outgoing Activities',
        outgoingCalls: 'Outgoing call | Outgoing calls',
        outgoingCommunications: 'Outgoing communication | Outgoing communications',
        outgoingEmails: 'Outgoing email | Outgoing emails',
        outgoingEmailsAndTextMessages: 'Outgoing email & SMS | Outgoing emails & SMS',
        outgoingTextMessages: 'Outgoing SMS | Outgoing SMS',
        performances: 'Performances',
        sales: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
        textMessages: 'SMS',
        visits: 'Visit | Visits',
        worked: 'Worked',
    },
    total: 'Total',
    variation: 'Variation',
};
