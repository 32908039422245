export default {
    all: 'All',
    finance: 'Credit',
    followUp: 'Communication',
    general: 'General',
    new: 'New',
    none: 'None',
    parts: 'Parts',
    reception: 'Reception',
    revival: 'Revive',
    service: 'Service',
    used: 'Used',
    bdc: 'BDC',
};
