export default {
    accounts: {
        index: 'Accounts',
        store: 'Create an account',
        update: 'Edit an account',
    },
    auth: {
        login: 'Login',
        logout: 'Logout',
        error: 'Connection error',
    },
    automations: {
        index: '@:navs.sidebar.automations',
        store: 'Create an automation',
        update: 'Edit an automation',
    },
    calendar: 'Calendar',
    dashboards: {
        activityReport: '@:dashboards.activityReport',
        allLead: '@:dashboards.allLead',
        commercial: '@:dashboards.commercial',
        event: '@:dashboards.event',
        leadXpress: '@:dashboards.leadXpress',
        loyalty: '@:dashboards.loyalty',
        phoneUp: '@:dashboards.phoneUp',
        renewal: '@:dashboards.renewal',
        saleTable: '@:dashboards.saleTable',
        service: '@:dashboards.service',
        walkIn: '@:dashboards.walkIn',
        webBoost: '@:dashboards.webBoost',
        webOrder: '@:dashboards.webOrder',
    },
    duplicates: '@:navs.sidebar.duplicates',
    flow: {
        webBoost: '@:navs.sidebar.flow @:dashboards.webBoost',
        phoneUp: '@:navs.sidebar.flow @:dashboards.phoneUp',
        leadXpress: '@:navs.sidebar.flow @:dashboards.leadXpress',
    },
    groups: {
        index: 'Groups',
    },
    imports: {
        index: '@:navs.sidebar.import',
        match: 'Column matching',
    },
    inTurn: {
        management: 'Next Up Management',
        show: 'Next Up',
    },
    leads: {
        store: 'Add lead',
        storeAssociated: 'Add associated lead',
        trash: 'Trash',
        update: 'Lead',
    },
    notFound: 'Page Not Found',
    objectives: {
        activityReport: '@:dashboards.activityReport - @:navs.sidebar.objectives',
        saleTable: '@:dashboards.saleTable - @:navs.sidebar.objectives',
    },
    search: 'Search',
    schedule: '@:navs.sidebar.schedule',
    suppliers: {
        accountNotFound: 'Account not found',
    },
    templates: {
        index: '@:navs.sidebar.templates',
        store: 'Create a template',
        update: 'Edit a template',
    },
    unauthorized: 'Unauthorized',
    users: {
        index: 'Users',
        store: 'Create a user',
        update: 'Edit a user',
    },
    sandbox: 'Sandbox',
};
