export default {
    dms: 'DMS',
    email: 'Web Lead',
    event: 'Event',
    loyalty: 'Loyalty',
    phone: 'Phone',
    preBooking: 'Pre-booking',
    renewal: '@.capitalize:lexicon.renewal.singular',
    sms: 'SMS',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webOrder: 'Web Order',

    abbreviations: {
        dms: 'DMS',
        email: 'Web Lead',
        event: 'Event',
        loyalty: 'Loyalty',
        phone: 'Phone',
        preBooking: 'Pre-b.',
        renewal: '@.capitalize:lexicon.renewal.abr ',
        sms: 'SMS',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        webOrder: 'Web Order',
    },
};
