export default {
    alerts: {
        index: {
            error: 'Failed fetching the tasks and events',
        },
        store: {
            error: 'Failed creating the task or event',
            success: 'Task or event created successfully',
        },
        show: {
            error: 'Failed fetching the taks or event',
        },
        update: {
            error: 'Failed updating the task or event',
            success: 'Task or event updated successfully',
        },
        destroy: {
            error: 'Failed deleting the task or event',
            success: 'Task or event deleted successfully',
        },
    },
};
