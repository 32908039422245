export default {
    landing: {
        404: 'Oops, wrong turn!',
        close: 'Close this window',
        confirmedText: 'You agreed to receive electronic communications from {dealer}. If you change your mind, you may',
        confirmedTitle: 'Thank you for confirming your communication preferences.',
        revokeLinkText: 'revoke your consent.',
        revokedText: 'You revoked your consent to receive communications from {dealer}. If you change your mind, you may give your consent back using the same link.',
        revokedTitle: 'Consent for electronic communications revoked.',
        subscribe: 'Subscribe',
    },

    alreadyConfirmed: 'The customer has already given his consent',
    cantValidateConsent: "Can't validate consent right now. Please try again later.",
    confirmConsent: 'Confirm extended consent',
    confirmConsentMessage: 'Choose your confirmation method.',
    confirmedState: 'Confirmed Consent',
    consent: 'Consent',
    consentExpiration: 'Consent end',
    consentNoteIsRequired: 'The note is required',
    consentNoteIsTooShort: 'The note is too short',
    consentNotePlaceholder: 'Note',
    consentReasonConfirmed: 'Confirmed on {date}',
    consentStatus: 'Consent',
    consentWarning: 'This confirmation method is not recommended. Make sure you have had verbal, written or electronic consent from your customer.',
    daysLeft: 'day left | days left',
    deactivate: 'Deactivate',
    expiresIn: 'Expires in {days} day | Expires in {days} days',
    expiry: 'Expiry',
    missingEmail: 'The customer must have a valid email address',
    moreInformationsButton: 'Anti-spam law information',
    moreInformationsFirstInfo: 'You can request his consent at any time. If you obtain it, you will retain your right of communication after this period.',
    moreInformationsHeader: "Without the explicit customer's consent, Canada's anti-spam law will prevents you from communicating electronically with him as of {date}",
    moreInformationsSecondInfo: 'At any time, the customer retains his right to withdraw his consent.',
    moreLawInfo: 'For full details about the law, please refer to',
    moreLawInfoLinkMessage: 'the official government website of canada',
    noValidEmail: 'No valid email',
    reason: 'Reason',
    temporaryState: 'Temporary Consent',
    waitingForDoubleOptIn: "Waiting for customer's consent",

    delays: {
        days: 'day | days',
        months: 'month | months',
        years: 'year | years',
    },

    durations: {
        lessThanTenDays: 'Less than 10 days',
        elevenToThirtyDays: '11 to 30 days',
        oneToThreeMonths: '1 to 3 months',
        fourToElevenMonths: '4 to 11 months',
        twelveToTwentyFourMonths: '12 to 24 months',
    },

    emailTypes: {
        home: 'home',
        work: 'work',
        other: 'other',
    },

    errors: {
        sendConsentEmailFailed: 'The sending has failed, please try again.',
        saveManualConsentFailed: 'The manual mode saving has failed, please try again.',
    },

    events: {
        appointment: 'A appointment was made on ',
        communication: 'A communication was made on ',
        initial: 'Lead created on ',
        sale: 'A sale was made on ',
        visit: 'A visit happened on ',
        contract: 'A contract ends on ',
    },

    methods: {
        email: 'Email',
        manual: 'Manual',
    },

    states: {
        temporary: 'Temporary',
        confirmed: 'Confirmed',
        expired: 'Expired',
    },

    statuses: {
        active: 'Active',
        expired: 'Expired',
        aboutToExpire: 'About to expire',
    },
};
