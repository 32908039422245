export default {
    alerts: {
        deletionInProgress: 'Deletion in progress, this may take a while...',
        presentedDateAndService: 'Only leads with a visit date that are not of division service will be added.',
        service: 'Only leads with the "Service" division will be created. If the division column is not in the import file, the "service" division will be assigned to the leads.<br/><br/>At least one owner field must be filled. (Advisor, service advisor or BDC agent).<br/>If no owner can be determined for a lead, the appointment will not be transferred to NioText.',
        preBooking: 'All leads will be created with the "Service" division, regardless of the divisions specified in the imported file.',
        processedSuccessfully: 'Import in progress...',
        updatedSuccessfully: 'The import has been updated successfully.',
    },
    deleteModal: 'Are you sure you want to delete this import?',
    deleteValidatedModal: 'Are you sure you want to delete this validated import?',
    eventEndDate: 'End date of the event',
    eventStartDate: 'Start date of the event',
    fieldInDatabase: "Activix CRM's Field",
    fieldInFile: 'Field in the file',
    fieldInfoModal: {
        description: 'The fields that are not in this table accept any format.',
        fieldInfo: 'Information',
        fieldName: 'Field name',
        title: 'List of fields to import',
    },
    fields: {
        address1: 'Address - line 1',
        address2: 'Address - line 2',
        app_confirmation: 'Appointment confirmation',
        app_confirmation_date: 'Appointment confirmation date',
        appointment: 'Appointment',
        appointment_date: 'Appointment date',
        appt_call: 'Phone appointment',
        appt_call_date: 'Phone appointment date',
        approved: 'Approved',
        appt_confirmation: 'Appointment confirmation',
        appt_confirmation_date: 'Appointment confirmation date',
        available_date: 'Veh. here date',
        'bdc_user:': 'BDC Agent',
        be_back: 'Be back (Yes / No)',
        be_back_date: 'Be back date',
        birth_date: 'Birth date',
        business: 'Business',
        call_date: 'Call date',
        city: 'City',
        civility: 'Civility',
        code: 'Code',
        casl_consent: 'Consent',
        business_name: 'Business name',
        comment: 'Comments',
        'commercial:': 'Commercial director',
        'financial:': 'F&I',
        country: 'Country',
        created_at: 'Creation date',
        csi: 'CSI',
        csi_date: 'CSI date',
        sector: 'Sector',
        budget: 'Budget',
        communication_preference: 'Communication preference',
        dealer_tour: 'Dealer tour',
        delivered: 'Delivered (Yes / No)',
        'delivered_by:': 'Delivered by',
        delivered_date: 'Delivered date',
        deliverable_date: 'Deliverable date',
        delivery_date: 'Delivery date',
        deposit: 'Deposit',
        write_up: 'Write-Up',
        discounted_date: 'Funded date',
        'division:name': 'Division (New / Used)',
        event: 'Event',
        'exchange_vehicles:allowed_mileage': 'Allowed km.',
        'exchange_vehicles:balance': 'Balance',
        'exchange_vehicles:bank:name': 'Institution',
        'exchange_vehicles:body_type': 'Body type',
        'exchange_vehicles:category': 'Category',
        'exchange_vehicles:category_rv': 'Category RV',
        'exchange_vehicles:color_exterior': 'Color',
        'exchange_vehicles:condition': 'Condition',
        'exchange_vehicles:end_contract_date': 'End contract date',
        'exchange_vehicles:end_warranty_date': 'End warranty',
        'exchange_vehicles:frequency': 'Frequency',
        'exchange_vehicles:fuel': 'Fuel',
        'exchange_vehicles:initial_cash': 'Initial cash',
        'exchange_vehicles:in_service_date': 'In service date',
        'exchange_vehicles:intention': 'Intention',
        'exchange_vehicles:length_max': 'Length max.',
        'exchange_vehicles:length_min': 'Length min.',
        'exchange_vehicles:link': 'Link',
        'exchange_vehicles:make': 'Make',
        'exchange_vehicles:mileage': 'Mileage',
        'exchange_vehicles:modality': 'Modality',
        'exchange_vehicles:model': 'Model',
        'exchange_vehicles:offer': 'Offer #',
        'exchange_vehicles:option': 'Option',
        'exchange_vehicles:payment': 'Payment',
        'exchange_vehicles:payment_with_tax': 'Payment with tax',
        'exchange_vehicles:price': 'Price',
        'exchange_vehicles:rate': 'Rate',
        'exchange_vehicles:requested': 'Requested $',
        'exchange_vehicles:residual': 'Residual',
        'exchange_vehicles:security_deposit': 'Security deposit',
        'exchange_vehicles:sleeping': 'Sleeping',
        'exchange_vehicles:sold_by': ' @.capitalize:lexicon.sold.singular by',
        'exchange_vehicles:sold_date': ' @.capitalize:lexicon.sold.singular date',
        'exchange_vehicles:stock': 'Stock #',
        'exchange_vehicles:term': 'Term',
        'exchange_vehicles:transmission': 'Transmission',
        'exchange_vehicles:trim': 'Trim',
        'exchange_vehicles:value': 'Value',
        'exchange_vehicles:vin': 'VIN',
        'exchange_vehicles:warranty': 'Warranty',
        'exchange_vehicles:weight': 'Weight',
        'exchange_vehicles:year': 'Year',
        'exchange_vehicles:accidented_damage_cost': 'Accident $',
        'exchange_vehicles:tire_type': 'Tire',
        first_name: 'First name',
        form: 'Form',
        gas: 'Fueled',
        imported_campaign: 'Campaign',
        inspected: 'Inspected',
        keyword: '@:clientCard.keyword',
        last_name: 'Last name (Complete name if single field)',
        last_presented_date: 'Last visit date',
        latest_comment: 'Notes',
        'lead_bank:name': 'Institution',
        'lead_emails:home': 'Personal email',
        'lead_emails:work': 'Work email',
        'lead_phones:cell': 'Phone cell',
        'lead_phones:home': 'Phone home',
        'lead_phones:work': 'Phone work',
        'lead_phones:work_extension': 'Phone work extension',
        locale: 'Language (fr / en) (Default : fr)',
        lost_reason: 'Lost reason',
        loyalty: 'Loyalty',
        navigation_history: '@:clientCard.navigationHistory',
        next_presented_date: 'Next revival',
        odometer_last_visit: 'Last visit mileage',
        open_work_order_date: 'W.O. opening',
        performance: 'Performance',
        postal_code: {
            CA: 'Postal Code',
            US: 'ZIP Code',
        },
        prepaid: 'Prepaid',
        prepared: 'Prepared',
        prepared_work_order: 'P.W.O.',
        presented: 'Visit (Yes / No)',
        presented_date: 'Visit date',
        paperwork_date: 'Paperwork date',
        progress_state: 'State',
        province: 'Province',
        qualification: 'Qualification',
        rating: 'Evaluation',
        rating_comment: 'Comments',
        reached_client: 'Reached client',
        referrer: '@:clientCard.referrer',
        refinanced: 'Refinanced',
        refinanced_date: 'Refinanced date',
        related_Id: "DMS's id",
        repair_date: 'Repair order date',
        repair_order: 'Repair order',
        result: 'Result',
        road_test: 'Test drive (Yes / No)',
        road_test_date: 'Test drive date',
        sale: '@.capitalize:lexicon.sale.singular (Yes / No)',
        sale_by_phone: '@.capitalize:lexicon.sale.singular by phone',
        sale_date: '@.capitalize:lexicon.sale.singular date',
        search_term: '@:clientCard.searchTerm',
        second_contact: 'Second name',
        second_contact_civility: 'Civility second contact',
        segment: 'Segment',
        'service_advisor:': 'Service advisor',
        'service_agent:': 'Service agent',
        service_cleaning_car: 'Service cleaning car',
        service_interval_km: 'Service interval KM',
        service_monthly_km: 'Service monthly KM',
        service_process_intention: 'Intention',
        service_process_transport: 'Transport',
        service_process_walk_around: 'Walk around',
        sex: 'Gender',
        sid: 'CRM ID',
        source: 'Source',
        status: 'Status',
        storage: 'Storage',
        take_over: 'Turn over (Yes / No)',
        take_over_date: 'Turn over date',
        'take_over_director:': 'T.O. by',
        twenty_four_hour: '24 hr',
        unsubscribe_call_date: 'Calls Unsubscribe',
        unsubscribe_email_date: 'Emails unsubscribe',
        unsubscribe_sms_date: 'SMS unsubscribe',
        unsubscribe_all_date: 'DND.',
        updated_at: 'Updated date',
        'user:': 'Advisor',
        walk_around: 'Walk around',
        'wanted_vehicles:accessories': 'Accessories',
        'wanted_vehicles:budget_max': 'Budget max.',
        'wanted_vehicles:budget_min': 'Budget min.',
        'wanted_vehicles:body_type': 'Body type',
        'wanted_vehicles:category': 'Category',
        'wanted_vehicles:category_rv': 'Category RV',
        'wanted_vehicles:color_exterior': 'Color',
        'wanted_vehicles:documentation': 'Documentation',
        'wanted_vehicles:end_warranty_date': 'End warranty',
        'wanted_vehicles:end_contract_date': 'End contract date',
        'wanted_vehicles:frequency': 'Frequency',
        'wanted_vehicles:fuel': 'Fuel',
        'wanted_vehicles:initial_cash': 'Initial cash',
        'wanted_vehicles:in_service_date': 'In service date',
        'wanted_vehicles:length_max': 'Length max.',
        'wanted_vehicles:length_min': 'Length min.',
        'wanted_vehicles:link': 'Link',
        'wanted_vehicles:make': 'Make',
        'wanted_vehicles:modality': 'Modality',
        'wanted_vehicles:model': 'Model',
        'wanted_vehicles:offer': 'Offer #',
        'wanted_vehicles:order_number': 'Order #',
        'wanted_vehicles:payment': 'Payment',
        'wanted_vehicles:payment_with_tax': 'Payment with tax',
        'wanted_vehicles:preparation': 'Preparation',
        'wanted_vehicles:price': 'Price',
        'wanted_vehicles:profit': 'Profit',
        'wanted_vehicles:rate': 'Rate',
        'wanted_vehicles:recorded_date': 'Reported on',
        'wanted_vehicles:sleeping': 'Sleeping',
        'wanted_vehicles:sold': ' @.capitalize:lexicon.sold.singular',
        'wanted_vehicles:stock': 'Stock #',
        'wanted_vehicles:stock_state': 'Stock state',
        'wanted_vehicles:suffix': 'Suffix',
        'wanted_vehicles:term': 'Term',
        'wanted_vehicles:tire': ' @.capitalize:lexicon.sold.singular tires',
        'wanted_vehicles:transmission': 'Transmission',
        'wanted_vehicles:trim': 'Trim',
        'wanted_vehicles:vin': 'VIN',
        'wanted_vehicles:weight': 'Weight',
        'wanted_vehicles:year': 'Year',
        'wanted_vehicles:year_max': 'Year max.',
        'wanted_vehicles:year_min': 'Year min.',
        work_order: 'W.O. #',
        work_order_closure_date: '@:clientCard.workOrderClosureDate',
        work_order_partial_closure_date: '@:clientCard.workOrderPartialClosureDate',
    },
    importRule: 'Import rule',
    importRules: {
        merge: 'Merge',
        create: 'Create',
    },
    leadType: 'Default lead type to use',
    needSidModal: {
        title: 'The "CRM ID" field is blank',
        description: 'The event leads will not be associated with the original leads',
    },
    eventDifferentDatesModal: {
        title: 'Different event dates',
        description: 'The entered event start and end dates will overwrite the previous ones:\n\nStart: {0}\nEnd: {1}',
    },
    revision: 'Revision',
    sections: {
        exchange_vehicle: 'Current vehicle',
        general: 'General',
        performance: 'Performance',
        personal: 'Personal information',
        process: 'Process',
        revival_service: 'Revival',
        wanted_vehicle: 'Wanted vehicle',
        customFields: 'Custom input',
        service: ' @.capitalize:lexicon.sold.singular services',
        service_process: 'Service process',
    },
    sectionsInfo: {
        revivalService: 'Only used in service leads',
    },
    segments: {
        conquest: 'Conquest',
        loyalty: 'Loyalty',
        notSold: 'Not  @:lexicon.sold.singular',
        other: 'Other',
        promo: 'Promo',
        service: 'Service',
        reminder: 'Reminder',
        endWarranty: 'End warranty',
        endLcap: 'End LCAP',
        endLnette: 'End LNETTE',
        nowShow: 'No show',
        csi: 'CSI',
    },
    subTitle: 'Please specify which fields we should use to import your data',
    table: {
        headings: {
            account: 'Account',
            originalFile: 'Original file',
            leadType: 'Lead Type',
            importedBy: 'Imported by',
            qtyInFile: 'To Import',
            qtyProcessed: 'Processed',
            created: 'Created',
            qtyMerged: 'Merged',
            createdAt: 'Created at',
            startedAt: 'Started at',
            finishedAt: 'Finished at',
            progress: 'Progression',
        },
        filter: 'Filter',
        noResults: 'No matching records',
        limit: 'Records:',
        count: 'Records',
        title: 'Last imports',
    },
    tooltips: {
        eventEndDate:
            'The end date of the event is mandatory for the 30 days of synchronization with the original lead',
        importRule:
            'The "Merge" option will merge existing leads into your account according to your preferences (Complete or Replace).\n\nThe "Create" option will add all leads by associating the corresponding leads.\n\n*N.B. The import will not make any changes to the existing lead type.',
        sex: 'In case of discordance between civility and gender, gender will be determined from civility',
    },
    upload: {
        button: 'Next step',
        delimiter: 'Delimiter',
        selectFile: 'Select the CSV file to import',
    },
    unselectedFieldsModal: {
        button: 'Import',
        description: 'Some fields from your CSV file will not be imported.',
        fieldName: 'Field name',
        title: 'List of unimported fields',
    },
    validateModal: {
        button: 'Validate',
        description: 'This will result in the merge and association of certain leads.',
        title: 'Are you sure you want to validate this import?',
    },
    allowEmptyCreatedAt: {
        button: 'Use today as the creation date',
        description: 'Are you sure you want to use today\'s date as the lead creation date?',
    },
};
