export default {
    advisor: 'Advisor',
    appt: 'Appointment',
    apptSaleByOrigin: 'Appt. and @:lexicon.sale.plural by source',
    apptWithWitoutPhone: 'Appt. with and without phone',
    calls: 'Calls',
    callsByAdvisors: 'Calls by advisor',
    callsByAppointments: 'Appointment by sources',
    callsbyModels: 'Calls & @:lexicon.sale.plural by models',
    callsBySources: 'Calls by sources',
    delivered: 'Delivered',
    lead: 'Leads',
    leadByForm: 'Leads by forms',
    leadByOrigin: 'Leads by supplier',
    leadBySource: 'Leads by source',
    leadByUserOrAccount: 'Leads by user',
    loading: 'Rendering graphs...',
    models: 'Models',
    new: 'New',
    noAppt: 'No appointment',
    noApptSaleByOrigin: 'No appointment / @:lexicon.sale.singular',
    noDivision: 'No division',
    noLeadAssigned: 'No lead assigned',
    noLeadByOrigin: 'No lead with source',
    noSale: 'No @:lexicon.sale.singular',
    noSaleRoadTest: 'No @:lexicon.sale.plural with or without test drives',
    noWalkIn: 'No @.capitalize:lexicon.walkIn.singular',
    numberOfAppointments: 'Number of appointments',
    numberOfCalls: 'Number of calls',
    numberOfLead: 'Number of leads',
    numberOfSales: 'Number of @:lexicon.sale.plural',
    numberOfWalkIn: 'Number of @.capitalize:lexicon.walkIn.singular',
    numberOfWebOrder: 'Number of Orders',
    numbers: 'Numbers',
    roadTest: 'test drive',
    sale: '@.capitalize:lexicon.sale.plural',
    saleByUser: '@.capitalize:lexicon.sale.plural by user',
    saleDeliveryByModels: '@.capitalize:lexicon.sale.plural & delivery by models',
    saleDeliveryByUsers: '@.capitalize:lexicon.sale.plural & delivery by users',
    saleRtByUser: '@.capitalize:lexicon.sale.plural with or without test drives',
    salesBySources: '@.capitalize:lexicon.sale.plural by sources',
    saleWithWitoutPhone: '@.capitalize:lexicon.sale.plural with and without phone',
    sources: 'Sources',
    title: 'Graphics',
    used: 'Used',
    useOnlyCreatedAt: 'This graph only uses the creation date to avoid duplicates',
    users: 'Users',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    walkInByUser: '@.capitalize:lexicon.walkIn.singular by user',
    webOrder: 'Orders',
    webOrderByOrigin: 'Orders by source',
    webOrderBySource: 'Orders by supplier',
    webOrderByForm: 'Orders by form',
    webOrderByUser: 'Orders by user',
    weekOf: 'Week of',
    weeks: 'Weeks',
    with: 'With',
    without: 'Without',
    withoutPhone: 'Without phone',
    withPhone: 'With phone',
    withPhoneStatInitial: 'The statistics with and without phone are based from the initial request',

    months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
    },
};
