export default {
    minutes: 'minute | minutes',
    hours: 'hour | hours',
    days: 'day | days',
    weeks: 'week | weeks',
    months: 'month | months',
    years: 'year | years',

    abbreviations: {
        seconds: 's',
        minutes: 'm',
        hours: 'h',
        days: 'd',
    },
};
