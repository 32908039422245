export default {
    title: 'Flow',
    priority: 'Priority | Priorities',
    addPriority: 'Add a priority',
    deletePriority: 'Delete a priority',
    selectDivision: 'You must select a division with the buttons below.',
    callPriority: 'Priority',
    emptyPriority: 'The flow is empty',
    leadxpressRequiresContact: 'Users added to the flow will have email notifications by default',
    allAdvisorsPriority: 'All advisors are in the flow',
    maxPriority: 'You have reached the maximum number of priorities',
    notifyPrecedentPriorities: 'Notify previous priorities',
    noUsers: 'No advisor for the division ',
    noLeadxpress: 'The Web Lead priority is deactivated, the priorities will not be applied.',
    schedule: 'Schedule',
    new: 'New',
    used: 'Used',
    usedAbr: 'Used',
    credit: 'Credit',
    service: 'Service',
    user: 'User | Users',
    noService: '{service} must be activated',
    hasDuplicateAlert: 'This user has access to receive duplicate leads',
    alerts: {
        show: {
            error: 'Failed fetching the flow',
        },
        update: {
            error: 'Failed updating the flow',
            success: 'Flow updated successfully',
        },
    },
    services: {
        webboost: 'WebBoost',
        phoneup: 'Phone-up',
        leadxpress: 'Web Lead',
    },
};
